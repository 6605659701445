import dayjs from "dayjs";
import Calendar from "react-calendar";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import "./calendar.styles.css";

interface ICustomCalendarProps {
  value: string | null;
  onChange: (date: Date) => void;
}

const CustomCalendar = ({ value, onChange }: ICustomCalendarProps) => {
  const handleDateChange = (value: Date) => {
    onChange(value);
  };

  return (
    <Calendar
      value={value ? new Date(value) : new Date()}
      onChange={(value) => handleDateChange(value as Date)}
      showNeighboringMonth={false}
      formatShortWeekday={(_, date) => dayjs(date).format("dddd").charAt(0)}
      tileClassName={`mb-2 rounded-full flex items-center justify-center h-[24px]`}
      tileContent={({ date: cdate, view }) => {
        const isActive = dayjs(cdate).isSame(value, "day");
        return view === "month" ? (
          <div
            className={`rounded-full w-6 h-6 flex items-center justify-center ${
              isActive && "bg-secondary-action text-white"
            } hover:bg-secondary-action hover:text-white`}
          >
            <p className="text-xs">{dayjs(cdate).date()}</p>
          </div>
        ) : null;
      }}
      view="month"
      className="border-[1px] rounded-2xl p-4 max-w-80 bg-white"
      prevLabel={<ChevronLeftIcon width={24} height={24} color="#2E7AB8" />}
      nextLabel={<ChevronRightIcon width={24} height={24} color="#2E7AB8" />}
      prev2Label={null}
      next2Label={null}
      minDate={new Date()}
    />
  );
};

export default CustomCalendar;
