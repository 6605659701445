import Switch from "./switch";
import { useScheduler } from "../contexts/SchedulerContext";
import { EventSubType } from "../types/scheduler";
import { useParams } from "react-router-dom";
import TimeScheduledDisplay from "./timeScheduledDisplay";
import NylasScheduler from "./nylasScheduler";
import { useEvent } from "../api/queries";
import HourBubble from "./hourBubble";

const TimePicker = ({ toNextStep }: { toNextStep: () => void }) => {
  const { eventId } = useParams();
  const { data: event } = useEvent(eventId || "");

  const { setTourType, dayAvailability, tourType } = useScheduler();

  const handleOnSwitch = (state: boolean) => {
    setTourType(state ? EventSubType.agentScheduled : EventSubType.virtual);
  };

  return (
    <>
      <div className="flex flex-col gap-6 items-center my-4 w-full">
        <h1 className="text-[32px] font-medium text-center tracking-[0.3px]">
          {event ? "Reschedule your tour" : "Schedule your tour"}
        </h1>

        {event && (
          <>
            <p>You are currently scheduled for:</p>
            <TimeScheduledDisplay date={new Date(event.start_datetime)} />
          </>
        )}

        <Switch onSwitch={handleOnSwitch} />
        {tourType === EventSubType.virtual && (
          <p className="text-center">
            Schedule a virtual video tour of our available apartments and
            community
          </p>
        )}
      </div>

      <NylasScheduler />

      <div className="flex gap-2 flex-wrap mt-8 w-full sm:justify-center">
        {dayAvailability.map((availability) => (
          <HourBubble
            key={availability.start_time.toISOString()}
            availability={availability}
            onSelect={toNextStep}
          />
        ))}
      </div>
    </>
  );
};

export default TimePicker;
