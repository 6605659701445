import { useScheduler } from "../contexts/SchedulerContext";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import api from "../api";
import { useCallback, useMemo, useState } from "react";
import validators from "../utils/validators";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { IContact } from "../types/contact";
import { Button, Input } from "./ui";
import { IProperty } from "../types/property";
import TimeScheduledDisplay from "./timeScheduledDisplay";
import { useCompanies, useTeamByCompanyProperty } from "../api/queries";
import { machineFormatPhoneNumber } from "../utils/phoneNumber";

dayjs.extend(advancedFormat);
dayjs.extend(timezone);

interface ITourSchedulerFormProps {
  isProspectKnown?: boolean;
  onBack: () => void;
}

const TourSchedulerForm = ({
  isProspectKnown = false,
  onBack,
}: ITourSchedulerFormProps) => {
  const navigate = useNavigate();
  const { companySlug, propertySlug } = useParams();
  const { data: company } = useCompanies({ slug: companySlug });
  const queryClient = useQueryClient();
  const properties: IProperty[] | undefined = queryClient.getQueryData([
    "properties",
    propertySlug,
    company?.slug,
  ]);
  const { data: teams } = useTeamByCompanyProperty(company?.id || "", properties ? properties[0].id : "");
  const { date, selectedHour, tourType, details, setDetails } =
    useScheduler();
  const [errors, setErrors] = useState({
    global: "",
    full_name: "",
    email: "",
    phone_number: "",
  });
  const [isLoading, setIsLoading] = useState(false)

  const selectedAvailableUser = useMemo(() => {
    if (!teams || teams.length === 0) {
      return null;
    }

    const user = teams[0].members.find((member) => {
      if (
        typeof member.user.connected_calendar_accounts === "undefined" ||
        member.user.connected_calendar_accounts === null ||
        member.user.connected_calendar_accounts.length === 0
      ) {
        return false;
      }

      return (
        member.user.connected_calendar_accounts[0].email ===
        selectedHour?.emails[0]
      );
    })?.user;
    return user;
  }, [teams, selectedHour]);

  const handleCreateEvent = useCallback(
    async (contact: IContact) => {
      const endDate = new Date(dayjs(date).add(30, "minutes").format());

      if (!properties) {
        return;
      }

      const title = `Tour at ${properties[0]?.name}`;

      if (!selectedAvailableUser) {
        return;
      }

      try {
        if (!date) {
          throw new Error("Date is required");
        }

        const data = {
          event_type: 0,
          start_datetime: date.toISOString(),
          end_datetime: endDate.toISOString(),
          timezone: dayjs.tz.guess(),
          title: title,
          guests: [contact.id],
          is_cancelled: false,
          property: properties[0]?.id,
          units: [],
          event_subtype: tourType,
          created_by: selectedAvailableUser.id,
        };
        const response = await api.post(`calendar/events/`, data);
        setErrors({ global: "", full_name: "", email: "", phone_number: "" });
        queryClient.setQueryData(["event", response.data.id], response.data);
        navigate(`${response.data.id}/preferences`);
      } catch (error: any) {
        if (typeof error.response.data === "object") {
          setErrors(error.response.data);
        } else {
          setErrors((prevState) => ({
            ...prevState,
            global: error.response.data,
          }));
        }
      }
    },
    [date, properties, selectedAvailableUser]
  );

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setErrors({ global: "", full_name: "", email: "", phone_number: "" });
    let hasErrors = false;

    if (!isProspectKnown) {
      if (!validators.isFilled(details.full_name)) {
        hasErrors = true;
        setErrors((prevState) => ({
          ...prevState,
          full_name: "Name is required",
        }));
      }

      if (!validators.isFilled(details.phone_number)) {
        hasErrors = true;
        setErrors((prevState) => ({
          ...prevState,
          phone_number: "Phone number is required",
        }));
      } else {
        const isValidPhoneNumber = validators.isPhoneNumber(
          details.phone_number
        );
        if (!isValidPhoneNumber) {
          hasErrors = true;
          setErrors((prev) => ({
            ...prev,
            phone_number: "Invalid phone number",
          }));
        }
      }
    }

    if (!validators.isFilled(details.email)) {
      setErrors((prev) => ({
        ...prev,
        email: "Email is required",
      }));
    } else {
      if (!validators.isEmail(details.email)) {
        hasErrors = true;
        setErrors((prev) => ({
          ...prev,
          email: "Invalid email address",
        }));
      }
    }

    if (hasErrors) {
      return;
    }

    if (!isProspectKnown && selectedAvailableUser) {
      const data = {
        ...details,
        phone_number: machineFormatPhoneNumber(details.phone_number),
        related_contacts: [],
        is_autocreated: false,
        created_by: selectedAvailableUser.id,
      };
      setIsLoading(true)
      try {
        await api
          .post("contacts/", data)
          .then(async ({ data }: { data: IContact }) => {
            setErrors({
              global: "",
              full_name: "",
              phone_number: "",
              email: "",
            });
            queryClient.setQueryData(["contact", data.id], data);
            await handleCreateEvent(data);
          });
      } catch (error: any) {
        const errors = error.response?.data;
        const isErrorObj =
          errors instanceof Object && errors.constructor === Object;

        const supportedErrorKeys = ["full_name", "phone_number", "email"];
        setErrors({
          global: "",
          full_name: "",
          phone_number: "",
          email: "",
        });

        if (isErrorObj) {
          Object.keys(errors).forEach((key) => {
            const mappedKey = supportedErrorKeys.includes(key) ? key : "global";
            setErrors((prev) => ({
              ...prev,
              [mappedKey]: Array.isArray(errors[key])
                ? errors[key][0]
                : errors[key],
            }));
          });
        } else {
          setErrors((prev) => ({
            ...prev,
            global: "Something went wrong. Please try again.",
          }));
        }
      } finally {
        setIsLoading(false)
      }
    }
  };

  const updateDetails = (key: string, value: string) => {
    setDetails((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    updateDetails(name, value);
  };

  return (
    <div className="flex flex-col items-center w-full max-w-[390px]">
      <TimeScheduledDisplay
        additionalClasses="mb-6 mt-9"
        hasGoBackButton
        onBack={onBack}
      />

      <h2 className="font-medium text-[24px] text-center tracking-[0.3px] mb-3">
        Just a few more details
      </h2>

      <form className="w-full flex flex-col gap-3" onSubmit={handleSubmit}>
        {!isProspectKnown && (
          <Input
            label="Your name*"
            value={details.full_name}
            name="full_name"
            onChange={handleChange}
            placeholder="First and last name"
            errorMessage={errors.full_name}
          />
        )}
        <Input
          label="Your email address*"
          name="email"
          value={details.email}
          onChange={handleChange}
          placeholder="We’ll email you a calendar invite"
          errorMessage={errors.email}
        />
        {!isProspectKnown && (
          <Input
            label="Your phone number*"
            type="number"
            name="phone_number"
            value={details.phone_number}
            onChange={handleChange}
            placeholder="We’ll text you tour reminders"
            errorMessage={errors.phone_number}
          />
        )}

        <Button disabled={isLoading} label={isLoading ? "Scheduling…" : "Schedule your tour"} type="submit" style={isLoading ? {
          backgroundColor: "#8BA2C0",
          opacity: 0.8
        } : {}}/>
      </form>
    </div>
  );
};

export default TourSchedulerForm;
