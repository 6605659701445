import { useEffect, useRef, useState } from "react";
import CustomCalendar from "../calendar";
import dayjs from "dayjs";

interface IDatePickerFieldProps {
  value: string;
  slug: string;
  onUpdate: ({ slug, value }: { slug: string; value: string | number }) => void;
}

const DatePickerField = ({ value, slug, onUpdate }: IDatePickerFieldProps) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const calendarRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handelToggleCalendar = () => setShowCalendar(!showCalendar);

  const handleDateChange = (date: Date) => {
    onUpdate({
      slug: slug,
      value: date.toISOString(),
    });
    setShowCalendar(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      calendarRef.current &&
      !calendarRef.current.contains(event.target as Node)
    ) {
      setShowCalendar(false);
    }
  };

  return (
    <div className="relative z-10 max-w-[300px] w-full">
      <button
        className={`flex justify-start w-full rounded-3xl border-border border-[1px] outline-none:focus p-4 text-sm ${
          !value && "text-[#9DA3AF]"
        }`}
        onClick={handelToggleCalendar}
      >
        <span>
          {value
            ? dayjs(value).format("dddd MMM Do")
            : "Your estimated move in date"}
        </span>
      </button>
      {showCalendar && (
        <div className="absolute block" ref={calendarRef}>
          <CustomCalendar value={value} onChange={handleDateChange} />
        </div>
      )}
    </div>
  );
};

export default DatePickerField;
