import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useScheduler } from "../contexts/SchedulerContext";
import { EventSubType } from "../types/scheduler";

const Switch = ({ onSwitch }: { onSwitch?: (state: boolean) => void }) => {
  const { tourType } = useScheduler();
  const [enabled, setEnabled] = useState(tourType === EventSubType.agentScheduled);
  const firstOptionRef = useRef<HTMLDivElement | null>(null);
  const secondOptionRef = useRef<HTMLDivElement | null>(null);

  const [firstOptionWidth, setFirstOptionWidth] = useState(0);
  const [secondOptionWidth, setSecondOptionWidth] = useState(0);

  const maxSwitchLength = 197;

  useEffect(() => {
    if (firstOptionRef.current) {
      setFirstOptionWidth(firstOptionRef.current.offsetWidth);
    }
    if (secondOptionRef.current) {
      setSecondOptionWidth(secondOptionRef.current.offsetWidth);
    }
  }, [firstOptionRef, secondOptionRef]);

  const transitionLength = useMemo(
    () => maxSwitchLength - secondOptionWidth,
    [secondOptionWidth]
  );

  const handleSwitch = () => {
    setEnabled((prevState) => {
      onSwitch?.(!prevState);
      return !prevState;
    });
  };

  return (
    <div className="flex items-center space-x-4">
      <div
        className={`relative w-[197px] flex justify-between border-1 border-border h-switch-h rounded-full cursor-pointer duration-300 ease-in-out overflow-hidden`}
        onClick={handleSwitch}
      >
        <span
          className={`absolute transform h-switch-h rounded-full transition-width-transform duration-300 ease-in-out bg-primary-complement`}
          style={
            enabled
              ? {
                  width: `${firstOptionWidth}px`,
                  transform: `translateX(${0}px)`,
                }
              : {
                  width: `${secondOptionWidth}px`,
                  transform: `translateX(${transitionLength}px)`,
                }
          }
        ></span>

        <Option label="In person" enabled={enabled} ref={firstOptionRef} />
        <Option label="Video" enabled={!enabled} ref={secondOptionRef} />
      </div>
    </div>
  );
};

export default Switch;

interface IOption {
  label: string;
  enabled: boolean;
}

const Option = forwardRef<HTMLDivElement, IOption>(
  ({ label, enabled }, ref) => {
    return (
      <div className="py-[10px] px-6 flex z-[1]" ref={ref}>
        <span
          className={`text-xs transition-colors duration-300 ease-in-out select-none font-medium ${
            enabled ? "text-primary-action" : "text-black"
          }`}
        >
          {label}
        </span>
      </div>
    );
  }
);
