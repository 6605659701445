import TimeScheduledDisplay from "./timeScheduledDisplay";
import { useMemo } from "react";
import { Button } from "./ui";
import { useNavigate, useParams } from "react-router-dom";
import { useContact, useEvent } from "../api/queries";
import Switch from "./switch";
import { EventSubType } from "../types/scheduler";

const TourManagerScreen = () => {
  const navigate = useNavigate();
  const { eventId } = useParams();
  const { data: event } = useEvent(eventId || "");
  const { data: contact } = useContact(event?.guests[0] || "");

  const title = useMemo(() => {
    if (!contact) {
      return "";
    }

    return `${contact.first_name}, manage your tour`;
  }, [contact]);

  return (
    <>
      <div className="flex flex-col gap-6 items-center mt-4 w-full">
        <h1 className="text-[32px] font-medium text-center tracking-[0.3px]">
          {title}
        </h1>

        {event && (
          <>
            <p>You are currently scheduled for:</p>
            <TimeScheduledDisplay date={new Date(event.start_datetime)} />
          </>
        )}

        <Switch />
        {event?.event_subtype === EventSubType.virtual && (
          <p className="text-center">
            Schedule a virtual video tour of our available apartments and
            community
          </p>
        )}
      </div>

      <div className="flex flex-col items-center w-full max-w-[390px]">
        <h3 className="font-medium text-[20px] text-center tracking-[0.3px] my-6">
          Can’t make it?
        </h3>

        <Button
          label="Reschedule tour"
          onClick={() => navigate("reschedule")}
        />
        <Button
          label="Cancel tour"
          onClick={() => navigate("cancel")}
          style={{
            marginTop: "8px",
            backgroundColor: "white",
            borderWidth: 1,
            borderColor: "#143C51",
          }}
          labelStyle={{ color: "#143C51" }}
        />
      </div>
    </>
  );
};

export default TourManagerScreen;
