import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { formatDateWithTimeRange } from "../utils";
import { useScheduler } from "../contexts/SchedulerContext";
import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const TimeScheduledDisplay = ({
  date,
  hasGoBackButton,
  additionalClasses,
  onBack,
}: {
  date?: Date;
  hasGoBackButton?: boolean;
  additionalClasses?: string;
  onBack?: () => void;
}) => {
  const location = useLocation();
  const isCancelingEvent = useMemo(() => {
    const lastRoute = location.pathname.split("/").filter(Boolean).pop();
    return lastRoute === "cancel";
  }, [location]);
  const { date: stateDate } = useScheduler();

  const actualDate = useMemo(() => date || stateDate, [date, stateDate]);

  return (
    <div
      className={`flex gap-2 items-center w-full relative justify-center ${additionalClasses}`}
    >
      {hasGoBackButton ? (
        <button onClick={() => onBack?.()}>
          <ArrowLeftCircleIcon width={30} height={30} color={"#143C51"} />
        </button>
      ) : isCancelingEvent ? (
        <XCircleIcon color="#143C51" height={24} width={24} />
      ) : (
        <CheckCircleIcon color="#143C51" height={24} width={24} />
      )}

      {actualDate && (
        <span
          className={`font-medium text-secondary-text tracking-[0.3px] ${
            isCancelingEvent && "line-through"
          }`}
        >
          {formatDateWithTimeRange(actualDate)}
        </span>
      )}
    </div>
  );
};

export default TimeScheduledDisplay;
